












































import DateHelper from '@/helpers/DateHelper';
import moment from 'moment';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { BookingStepLoading } from './models/BookingStep';

@Component({
  components: {
  },
})
export default class BookingStepSelectDate extends Vue {
  @Prop() 
  private value!: string;
  @Prop() 
  private loading!: BookingStepLoading;
  @Prop({default: true}) 
  hasPrevStep!: boolean;


  minDate: string = new Date().toISOString().substr(0, 10);

  datePickerOpen: boolean = false;

  selectedDateFormattedIsValid: boolean = true;

  get locale() {
    return this.$store.getters['app/locale'];
  }

  get rules(): any {
    return {
      dateFormattedFormat: (v: string) => 
        !!this.formattedDateIsValid(v) || 
        this.$t("general.check_date_format"),
      selectedDateMinDate: (v: string) => 
        !!this.formattedDateIsAtOrAfterIsoDate(v, this.minDate) || 
        this.$t(
          "general.date_cannot_be_before_date", 
          { 
            date: DateHelper.isoDateToFinnishDate(this.minDate)
          }
        ),
      isoDateFormat: (v: string) => 
        !!this.formattedDateIsValid(v) || 
        this.$t("general.check_date_format"),
      selectedIsoDateMinDate: (v: string) => 
        !!this.isoDateIsAtOrAfterIsoDate(v, this.minDate) || 
        this.$t(
          "general.date_cannot_be_before_date", 
          { 
            date: DateHelper.isoDateToFinnishDate(this.minDate)
          }
        ),
    }
  }


  // Start date formatted conversion, input handlnig & validation
   get selectedIsoDate(): string {
    // console.log(" get selectedDateFormatted triggered")
    return this.selectedDate;
  }
  set selectedIsoDate(val: string){
    const selectedIsoDateIsValid = this.isoDateIsValid(val) && this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
    if (!selectedIsoDateIsValid){
      return;
    }
    this.selectedDate =  DateHelper.finnishDateToIsoDate(val) as string;
  }
  get selectedDateFormatted(): string {
    // console.log(" get selectedDateFormatted triggered")
    return DateHelper.isoDateToFinnishDate(this.selectedDate);
  }
  set selectedDateFormatted(val: string){
    const selectedDateFormattedIsValid = this.formattedDateIsValid(val) && this.formattedDateIsAtOrAfterIsoDate(val, this.minDate);
    if (!selectedDateFormattedIsValid){
      return;
    }
    this.selectedDate =  DateHelper.finnishDateToIsoDate(val) as string;
  }
  @Watch("selectedDateFormatted")
  selectedDateFormattedChanged(val: string){
    this.selectedDateFormattedIsValid = this.formattedDateIsValid(val) && this.formattedDateIsAtOrAfterIsoDate(val, this.minDate);
  }
  // onSelectedDateFormattedInput(val: string){
  //   console.log("onSelectedDateFormattedInput, val:", val)
  //   this.selectedDateFormattedIsValid = this.formattedDateIsValid(val) && this.formattedDateIsAtOrAfterIsoDate(val, this.minDate);
  // }
  onSelectedDateFormattedChange(val: string){
    this.selectedDateFormatted = val;
  }
  onSelectedDateInput(val: string){
    this.datePickerOpen = false
  }

  selectedIsoDateIsValid: boolean = false;

  @Watch("selectedIsoDate")
  selectedIsoDateChanged(val: string){
    this.selectedIsoDateIsValid = this.isoDateIsValid(val) && this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
  }
  onSelectedIsoDateChange(val: string){
    this.selectedIsoDate = val;
  }
  onSelectedIsoDateInput(val: string){
    this.datePickerOpen = false
  }

  isoDateIsAtOrAfterIsoDate(isoDate: string, otherIsoDate: string){
    return !moment(isoDate).isBefore(moment(otherIsoDate));
  }

  // Generic date validation
  formattedDateIsAtOrAfterIsoDate(val: string, otherIsoDate: string){
    const isoDate = DateHelper.finnishDateToIsoDate(val);
    return !moment(isoDate).isBefore(moment(otherIsoDate));
  }

  formattedDateIsValid(val: string): boolean {
    const isoDate = DateHelper.finnishDateToIsoDate(val);
    if (!isoDate){
      console.warn("formattedDateIsValid:" + " cannot convert to iso date: " + val);
      return false;
    }
    const valMoment = moment(isoDate);
    if (!valMoment.isValid()){
      console.warn("formattedDateIsValid:" + " moment isValid=false: " + valMoment);
      return false;
    }
    return true;
  }

  isoDateIsValid(val: string): boolean {
    const isIsoDate = DateHelper.isIsoDate(val);
    if (!isIsoDate){
      console.warn("isoDateIsValid:" + " cannot convert to iso date: " + val);
      return false;
    }
    const valMoment = moment(val);
    if (!valMoment.isValid()){
      console.warn("isoDateIsValid:" + " moment isValid=false: " + valMoment);
      return false;
    }
    return true;
  }

  

  get selectedDateIsValid(): boolean {
    return this.selectedDateFormattedIsValid;
  }

  get selectedDate(): string {
    return this.value;
  }
  set selectedDate(val: string) {
    this.$emit("change", val);
  }
 
}
