import Vue from 'vue';
import Router, { Route } from 'vue-router';
import i18n from './i18n';
// import app from './main';
import store from './store/store';
import Home from '@/views/Home.vue';
import Invitation from '@/views/Invitation.vue';
import Passengers from '@/views/Passengers.vue';
import PassengerTransportationPermissions from '@/views/PassengerTransportationPermissions.vue';
import Booking from '@/views/Booking.vue';
import Trips from '@/views/Trips.vue';
import TripsEdit from '@/views/TripsEdit.vue';
import Messages from '@/views/Messages.vue';
import CancelTrips from '@/views/CancelTrips.vue';
import PersonalInfo from '@/views/PersonalInfo.vue';
import Password from '@/views/Password.vue';
import Privacy from '@/views/Privacy.vue';
import LoggedOut from '@/views/LoggedOut.vue';
import RegisterTravelCard from '@/views/RegisterTravelCard.vue';
import AccessibilityReport from '@/views/AccessibilityReport.vue';

Vue.use(Router);

function loadView(view: string) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/views/${view}.vue`);
}

const router = new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home, //loadView('Home'),
      meta: {
        title: 'home',
      },
    },
    {
      path: '/invitation/:code',
      name: 'invitation',
      component: Invitation, //loadView('Invitation'),
    },
    {
      path: '/passengers',
      name: 'passengers',
      component: Passengers, //loadView('Passengers'),
      meta: {
        requireAuthentication: true,
        title: 'passengers',
      },
    },
    {
      path: '/transportationPermissions',
      name: 'transportationPermissions',
      component: PassengerTransportationPermissions, //loadView('PassengerTransportationPermissions'),
      meta: {
        requireAuthentication: true,
        title: 'transportationPermissions',
      },
    },
    {
      path: '/booking',
      name: 'booking',
      component: Booking, //loadView('Booking'),
      meta: {
        requireAuthentication: true,
        title: 'booking',
      },
    },
    {
      path: '/trips',
      name: 'trips',
      component: Trips, //loadView('Trips'),
      meta: {
        requireAuthentication: true,
        title: 'trips',
      },
    },
    {
      path: '/trips/:id/:date',
      name: 'trip-edit',
      component: TripsEdit, //loadView('TripsEdit'),
      meta: {
        requireAuthentication: true,
      },
    },
    {
      path: '/messages',
      name: 'messages',
      component: Messages, //loadView('Messages'),
      meta: {
        requireAuthentication: true,
        title: 'messages',
      },
    },
    {
      path: '/canceltrips',
      name: 'canceltrips',
      component: CancelTrips, //loadView('CancelTrips'),
      meta: {
        requireAuthentication: true,
        title: 'canceltrips',
      },
    },
    {
      path: '/personal-info',
      name: 'personal-info',
      component: PersonalInfo, //loadView('PersonalInfo'),
      meta: {
        requireAuthentication: true,
        title: 'personal-info',
      },
    },
    {
      path: '/password',
      name: 'password',
      component: Password, //loadView('Password'),
      meta: {
        requireAuthentication: true,
        title: 'password',
      },
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: Privacy, //loadView('Privacy'),
      meta: {
        title: 'privacy',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: LoggedOut, //loadView('LoggedOut'),
      beforeEnter: () => store.dispatch('auth/logout')
    },
    {
      path: '/register-travel-card',
      name: 'register-travel-card',
      component: RegisterTravelCard, //loadView('RegisterTravelCard'),
      meta: {
        title: 'register-travel-card',
      },
    },
    {
      path: '/track/:link',
      name: 'track',
      component: TripsEdit, //loadView('TripsEdit'),
      meta: {
        title: 'track',
      },
    },
    {
      path: '/accessibility-report',
      name: 'accessibility-report',
      component: AccessibilityReport, //loadView('AccessibilityReport'),
      meta: {
        title: 'accessibility-report',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Set correct locale if url contains lang=FI
  if (to.query.lang) {
    store.commit('app/changeLocale', to.query.lang);
  }
  if (to.matched.some(route => route.meta.requireAuthentication)) {
    if (!store.getters['auth/isAuthenticated']) {
      next({
        name: 'home',
        query: { redirect: to.fullPath },
      });
      return;
    }
  }

  document.title = getTitle(to);
  next();
});

function getTitle(to: Route) {
  const theme = store.getters['app/theme'];
  const themeName = theme ? theme.name : '';
  const pageName = to.meta && to.meta.title ? i18n.t(`title.${to.meta.title}`) : '';

  if (!pageName) {
    return themeName;
  }

  return `${pageName} - ${themeName}`;
}

export default router;
