



















































































































































import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import TripListItem from "@/components/TripListItem.vue";
import MenuHelper from "@/helpers/MenuHelper";

@Component({
  components: {
    TripListItem,
  },
})
export default class Trips extends Vue {
  get locale() {
    return this.$store.getters["app/locale"];
  }

  get passengers(): IPassenger[] {
    return this.$store.getters["passenger/passengers"];
  }

  get minDate() {
    return this.tripDates[0];
    // return new Date().toISODate();
  }

  get maxDate() {
    return this.tripDates[this.tripDates.length - 1];
  }

  get tripDates(): string[] {
    return this.$store.getters["trip/dates"];
  }

  get nextTripAt() {
    console.log("nextTripAt", "this.date:", this.date);
    const current = new Date(this.date);
    const nextTrips = this.tripDates.filter((d) => {
      return current.getTime() < new Date(d).getTime();
    });
    if (nextTrips.length > 0) {
      return this.displayDate(nextTrips[0]);
    }
  }

  get dateTitle() {
    return this.displayDate(this.date);
  }

  get prevDateTitle() {
    return moment(this.prevDay).format("dddd D. MMMM");
  }

  get nextDateTitle() {
    return moment(this.nextDay).format("dddd D. MMMM");
  }

  get trips() {
    return this.$store.getters["trip/trips"](this.date, this.filter);
  }
  dateselectopen = false;
  date: string = new Date().toISOString().substring(0, 10);

  filter: IPassenger | null = null;

  menuTabPressed(event: any) {
    MenuHelper.menuTabPressed(event, this);
  }

  openTripDialog(trip: ITrip) {
    const date = (trip.startDateTime || trip.endDateTime).split("T")[0];
    this.$router.push("/trips/" + trip.id + "/" + date);
  }

  get currentDate() {
    return new Date(this.date);
  }

  get comingDates() {
    return this.tripDates
      .map((d) => new Date(d))
      .filter((d) => d.getTime() > this.currentDate.getTime());
  }

  get pastDates() {
    return this.tripDates
      .map((d) => new Date(d))
      .filter((d) => d.getTime() < this.currentDate.getTime());
  }

  get nextDay() {
    return this.comingDates[0] || this.currentDate;
  }

  get prevDay() {
    return this.pastDates.length === 0
      ? this.currentDate
      : this.pastDates[this.pastDates.length - 1];
  }

  get hasPrevDay() {
    return this.prevDay && this.prevDay !== this.currentDate;
  }

  showNextDay() {
    this.date = this.nextDay.toISOString().substring(0, 10);
  }

  showPrevDay() {
    this.date = this.prevDay.toISOString().substring(0, 10);
  }

  filterPassenger(passenger: IPassenger) {
    this.filter = passenger;
  }

  clearPassengerFilter() {
    this.filter = null;
  }

  dateColor(date: string) {
    const trips = this.$store.getters["trip/trips"](date, this.filter);
    const colors = trips
      .map((t: any) => this.$randomColor(t.passenger.id))
      .filter((v: any, i: number, a: any) => a.indexOf(v) === i);
    if (colors.length > 1) {
      return "primary";
    } else if (colors.length === 1) {
      return colors[0];
    }
  }

  mounted() {
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
  }

  private displayDate(date: string): string {
    const title = moment(date).calendar(undefined, {
      lastDay: `[${this.$t("dates.yesterday")}]`,
      sameDay: `[${this.$t("dates.today")}]`,
      nextDay: `[${this.$t("dates.tomorrow")}]`,
      lastWeek: "dd D.M.YYYY",
      nextWeek: "dd D.M.YYYY",
      sameElse: "dd D.M.YYYY",
    });
    return title.toLowerCase();
  }
}
