





































































































import Themes from "@/themes";
import ITheme from "@/themes/ITheme";
import { Component, Vue, Prop } from "vue-property-decorator";
import PhoneNumber from "./PhoneNumber.vue";

@Component({
  components: {
    PhoneNumber,
  },
})
export default class BookingStepEnterAdditionalInfo extends Vue {
  @Prop({required: true})
  order!: IBookingOrder;
  @Prop()
  rules!: any;
  @Prop({default: true}) 
  hasPrevStep!: boolean;
  @Prop({default: ''}) 
  bookingPhoneNumber!: string;

  get isLikumaPermission() {
    if (this.order.quota) {
      return (
        this.order.quota.decisionType === "VPL" ||
        this.order.quota.decisionType === "SHL"
      );
    }
    return false;
  }

  get theme(): ITheme {
    return this.$store.getters["app/theme"];
  }

  get requirePhoneNumber() {
    return (
      this.theme.id === Themes.themeMeneva.id ||
      this.theme.id === Themes.themeFixutaxi.id
    );
  }
}
