import { AppInsights } from 'applicationinsights-js';
import { Route } from 'vue-router';

// Get instrumentation key from build settings
const instrumentationKey = process.env.VUE_APP_APPINSIGHTS_INSTRUMENTATIONKEY;

/**
 * Usage:
 * Vue.use(applicationInsights, {
 *   router: router
 * })
 */
function install(Vue: any, options: any) {
  if (!instrumentationKey) {
      // Do nothing if instrumentation key is not set
      return;
  }

  Vue.appInsights = AppInsights;
  Vue.appInsights.downloadAndSetup({ instrumentationKey });

  // Track vue errors to application insights
  Vue.config.errorHandler = (exception: Error, component: string, info: string) => {
    Vue.appInsights.trackException({ exception, component, info });
  };

  // Setup Vue router to track pages if router is passed in with options
  const router = options.router;
  if (router) {
    const baseName = options.baseName || '(Rideit)';

    router.beforeEach( (route: Route, from: Route, next: () => void) => {
      const name = baseName + ' / ' + route.name;
      Vue.appInsights.startTrackPage(name);
      next();
    });

    router.afterEach((route: Route) => {
      const name = baseName + ' / ' + route.name;
      const url = location.protocol + '//' + location.host + route.fullPath;
      Vue.appInsights.stopTrackPage(name, url);
    });
  }

  // Define getter to all Vue components
  Object.defineProperty(Vue.prototype, '$appInsights', {
    get: () => Vue.appInsights,
  });
}

export default install;
