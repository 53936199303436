import Vue from 'vue';
import moment from 'moment-timezone';

const timezone = 'Europe/Helsinki';
moment.tz.setDefault(timezone);
Vue.prototype.$moment = moment;

export default {
  availableLocales: ['fi', 'en', 'sv'],
  isValid(locale: string) {
    return locale && this.availableLocales.indexOf(locale) >= 0;
  },
  defaultLanguage() {
    
    let locale = this.availableLocales[1];
    if (document.location.host.endsWith(".fi")) {
      locale = this.availableLocales[0];
    }
    // First try localstorage, then browser lang and then set default language by host url
    const storageLocale = localStorage.getItem('locale') ||
    navigator.language.substring(0,2).toLocaleLowerCase();
    
    if (this.isValid(storageLocale)) {
      locale = storageLocale;
    } 
    moment.locale(locale);
    return locale;
  },
};
