





































































































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
// import Booking from "./Booking/models/Booking";
import IAddressViewModel, {
  AddressTypeEnum,
} from './Booking/models/IAddressViewModel';
import IGooglePlaceAddressComponents from './Booking/models/IGooglePlaceAddressComponents';
import IGooglePlaceCachedPrediction from './Booking/models/IGooglePlaceCachedPrediction';
import PlacePickerDestination, { DestinationSource } from './Booking/models/PlacePickerDestination';
import { v4 as uuidv4 } from 'uuid';


@Component({
  components: {},
})
export default class PlacePickerG extends Vue {

  componentInstanceUuid: string = uuidv4();
  mountedAt: Date | null = null;

  get attachId() {
    return this.attach ? this.attach : "attach_" + this.componentInstanceUuid;
  }

  get filteredItems() {
    if (!this.search) {
      let allItems = this.items;
      if (this.passengerPlaces.length > 0) {
       allItems =  allItems.concat(this.passengerPlaces);
      }
      allItems = allItems.filter(i => i);
      // deduplicate, otherwise will have this.items + this.passengerPlaces (which also return this.items)
      allItems = [...new Map(allItems.map(item => [item.id, item])).values()];
      return allItems;
    }
    const query = this.search.toLowerCase();
    let items = this.items;
    items = items.concat(this.passengerPlaces).filter(i => i);

    return items.filter(i => i.alias && i.alias.toLowerCase().includes(query))
      .sort((a, b) => {
        let scoreA = 0;
        let scoreB = 0;

        if (a.alias) {
          const aliasA = a.alias.toLowerCase();
          if (aliasA === query) {
            scoreA += 1000000000;
          } else if (aliasA.startsWith(query)) {
            scoreA += 1000000;
          }
        }

        if (b.alias) {
          const aliasB = b.alias.toLowerCase();
          if (aliasB === query) {
            scoreB += 1000000000;
          } else if (aliasB.startsWith(query)) {
            scoreB += 1000000;
          }
        }

        return scoreB - scoreA;
      });
  }


  get favoritePlaces(): PlacePickerDestination[] {
    return [];
  }

  get isValid() {
    return !(this.$refs.autocompleteRef as any).hasError;
  }

  @Prop({ default: '' }) label!: string;
  @Prop({ default: '' }) ariaLabel!: string;
  @Prop({ default: '' }) placeholder!: string;
  @Prop({ default: '' }) attach!: string;
  @Prop({ default: false }) required!: boolean;
  @Prop({ default: false }) autofocus!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) clearable!: boolean;
  @Prop({ default: false }) allowAllPlaces!: boolean;
  @Prop({ default: () => [] }) places!: IDestination[];
  @Prop({ default: null }) value!: IDestination | null;
  @Prop({ default: null }) rules!: any[];

  isLoading: boolean = false;

  items: PlacePickerDestination[] = [];
  search: string = '';
  autocompleteSessionToken!: google.maps.places.AutocompleteSessionToken;

  model: IDestination | null = null;

  placeSelectedFlag: boolean = false;

  googlePlacesAutocompleteService!: google.maps.places.AutocompleteService;
  googlePlacesService!: google.maps.places.PlacesService;
  getGooglePlacesPredictionsTimeout: number = 0;

  lastEmitted: IDestination | null = null;
  passengerPlaces: PlacePickerDestination[] = [];

  get validationErrors(): string[]{
    const autocompleteRef = this.$refs.autocompleteRef as Vue;
    if (autocompleteRef){
      return (autocompleteRef as any).errorBucket as string[];
    }
    return [];   
  }

  @Watch('search')
  onSearchChanged(val: string, oldVal: string) {
    if (this.placeSelectedFlag) {
      this.placeSelectedFlag = false;
      return;
    }

    if (!this.allowAllPlaces) {
      this.items = this.passengerPlaces;
      return;
    }

    this.items = this.favoritePlaces;
    this.isLoading = val ? true : false;
    this.getGooglePlacesPredictions(
      val,
      this.autocompleteSessionToken,
      this.onPlacesResultsAvailable,
    );

  }

  onPlacesResultsAvailable(predictions: any, status: any) {
    this.isLoading = false;
    this.items = this.concatGooglePlacesPredictions(
      [],
      predictions,
      status,
    ).map(p => PlacePickerDestination.fromGooglePlacePrediction(p));
  }

  @Watch('model', { immediate: true })
  onModelChanged(val: PlacePickerDestination | null, oldVal: PlacePickerDestination | null) {
    this.placeSelectedFlag = true;
    if (val && val.source) {
      if (val.source === DestinationSource.GooglePlacePrediction) {
        this.getGooglePlacesPlaceDetails(
          val.prediction as google.maps.places.AutocompletePrediction,
          this.autocompleteSessionToken,
          this.onGetDetailsComplete,
        );
      } else {
        if (this.lastEmitted != this.model){
          this.$emit('input', this.model);
          this.lastEmitted = this.model;

          const mountedAtMilliseconds = this.mountedAt ? this.mountedAt.getTime() : new Date().getTime();
          const mountedMillisecondsAgo = new Date().getTime() - mountedAtMilliseconds;
          if (mountedMillisecondsAgo > 500){
            window.setTimeout(() => {
              const changeButtonComponent = this.$refs.changeButtonRef as Vue;
                if (changeButtonComponent){
                  (changeButtonComponent as any).$el.focus();
                }
            }, 200);
          }
        }
        return;
      }
    } else {
      this.$emit('input', null);
      this.lastEmitted = null;
    }
   
  }

  @Watch('value', { immediate: true }) 
  onValueChanged(value: any) {
    if (value !== this.lastEmitted) {
      if (value) {
        const destination = PlacePickerDestination.fromRideitDestination(value);
        const foundDestination = this.items.find(d => d.alias === destination.alias);
        if (!foundDestination) {
          this.items.push(destination);
        }
        this.model = foundDestination ? foundDestination : destination;
      } else {
        this.model = value;
      }
    }
   
  }

  @Watch('places', { immediate: true }) 
  onPlacesChanged() {
    this.passengerPlaces = this.places.map(p => PlacePickerDestination.fromRideitDestination(p));
    // TODO: The below original line will make it impossible to remove items from the items, hence it's commented out
    // this.items = this.items.concat(this.passengerPlaces);
    this.items = this.passengerPlaces;
  }

  onGetDetailsComplete(result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) {
    const place = this.googlePlacesApiResultToAddressViewModel(
      result,
      status,
    );
    if (place) {
      this.items = this.items.concat(place);
      this.model = place;
      this.lastEmitted = place;
      this.$emit('input', place);
    }
    // Create a new session token because the current has now been used and can't be re-used
    this.autocompleteSessionToken = this.createNewGoogleAutocompleteSessionToken();
    this.$emit('placeSelected');
  }

  timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async mounted() {
    this.mountedAt = new Date();
  }


  // Usage in Chrome console: __ppicker.getPredictionResultsAsJson(items: IGooglePlaceCachedPrediction)
  // getPredictionResultsAsJson(item: IGooglePlaceCachedPrediction) {
  //     this.getPredictionResultAsJsonAsync(item);
  // }

  // async getPredictionResultAsJsonAsync(item: IGooglePlaceCachedPrediction) {
  //   this.ensureGooglePlacesServicesCreated();
  //   await this.timeout(2000);

  //   this.getGooglePlacesPlaceDetails(
  //     item.prediction,
  //     this.createNewGoogleAutocompleteSessionToken(),
  //     (result, status) => {
  //       // const fav = items.find(p => p.prediction.place_id === result.place_id);
  //       item.result = result;
  //       // console.log("getPredictionResultAsJsonAsync done. Json results:");
  //       // console.log(JSON.stringify(item));
  //     },
  //   );

  // }

  ensureGooglePlacesServicesCreated() {
    if (!this.autocompleteSessionToken) {
      this.autocompleteSessionToken = this.createNewGoogleAutocompleteSessionToken();
    }
    if (!this.googlePlacesAutocompleteService) {
      this.googlePlacesAutocompleteService = new (window as any)
        .google.maps.places.AutocompleteService() as google.maps.places.AutocompleteService;
    }
    if (!this.googlePlacesService) {
      this.googlePlacesService = new (window as any).google.maps.places.PlacesService(
        this.$refs.googlePlacesContainer,
      ) as google.maps.places.PlacesService;
    }
  }

  createNewGoogleAutocompleteSessionToken(): google.maps.places.AutocompleteSessionToken {
    const sessionToken = new google.maps.places.AutocompleteSessionToken();
    return sessionToken;
  }

  getGooglePlacesPredictions(
    search: string,
    sessionToken: google.maps.places.AutocompleteSessionToken,
    callback: (
      result: google.maps.places.AutocompletePrediction[],
      status: google.maps.places.PlacesServiceStatus) => void,
    ) {
    if (search) {
      this.ensureGooglePlacesServicesCreated();
      if (this.getGooglePlacesPredictionsTimeout) {
        window.clearTimeout(this.getGooglePlacesPredictionsTimeout);
      }
      this.getGooglePlacesPredictionsTimeout = window.setTimeout(() => {
        const request: google.maps.places.AutocompletionRequest = {
          input: search,
          componentRestrictions: {
            country: 'fi',
          },
          sessionToken,
        };
        this.googlePlacesAutocompleteService.getPlacePredictions(
          request,
          callback,
        );
      }, 600);
    }
  }

  concatGooglePlacesPredictions(
    items: IGooglePlaceCachedPrediction[],
    predictions: google.maps.places.AutocompletePrediction[],
    status: google.maps.places.PlacesServiceStatus,
  ): IGooglePlaceCachedPrediction[] {
    if (status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        return items.concat( predictions.map(p => {
          p.description = p.description.replace(/, Suomi$/, '');
          return {
             prediction: p,
          } as IGooglePlaceCachedPrediction;
        }) );
      } else {
        alert('Error when getting place autocomplete results from Places API: ' + status);
      }
    }
    return items;
  }

  getGooglePlacesPlaceDetails(
    prediction: google.maps.places.AutocompletePrediction,
    sessionToken: google.maps.places.AutocompleteSessionToken,
    callback: (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => void,
  ) {
    if (prediction) {
      this.ensureGooglePlacesServicesCreated();
      const request: google.maps.places.PlaceDetailsRequest = {
        fields: [
          'address_components',
          'formatted_address',
          'geometry.location',
          'name',
          'place_id',
          'types',
          'vicinity',
        ],
        placeId: prediction.place_id,
        sessionToken,
      };

      this.googlePlacesService.getDetails(
        request,
        callback,
      );
    }
  }

  googlePlacesApiResultToAddressViewModel(
    result: google.maps.places.PlaceResult,
    status: google.maps.places.PlacesServiceStatus,
  ): IDestination | undefined {

    if (status !== google.maps.places.PlacesServiceStatus.OK) {
      alert('Error: Google Places API: googlePlacesService.getDetails: ' + status);
      return;
    }

    if (!result.address_components) {
      alert('Error: Google Places API: googlePlacesService.getDetails: No address components found in result.');
      return;
    }

    const destination = PlacePickerDestination.fromGooglePlaceResult(result);

    if (!destination.address) {
      alert('Error: Google Places API: googlePlacesService.getDetails: Address cannot be found in result.');
      return;
    }

    if (!result.geometry) {
      alert('Error: Google Places API: googlePlacesService.getDetails: Coordinates cannot be found in result.');
      return;
    }
    return destination;
  }

  focus() {
    (this.$refs.autocompleteRef as any).focus();
  }

  locationIcon(destination: IDestination | null): string {
    let icon = '$fas-fa-map-marker';
    if (destination) {
      if (destination.isHome) {
          icon = '$fas-fa-home';
      }
      if (destination.isPickup) {
        icon = '$fas-fa-street-view';
      }
      if (destination.isMain) {
        icon = '$fas-fa-school';
      }
    }
    return icon;
  }

  onChangeClick(){
    this.model = null; 
    window.setTimeout(() => { 
      this.focusInput();
    }, 150)
  }

  focusInput(){
    const autocomplete = this.$refs.autocompleteRef as Vue;
    if (!autocomplete){
      return;
    }
    const inputElem = autocomplete.$refs.input as HTMLInputElement;
    if (!inputElem){
      return;
    }
    inputElem.focus();
  }

}
