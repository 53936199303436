import { Module, Commit, ActionContext } from 'vuex';
import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';
import i18n from '../i18n';
import localeService from '@/services/localeService';
import restService from '@/services/restService';
import ITheme from '@/themes/ITheme';
import Themes from '@/themes';
import { IRootState } from './store';
import cacheService from '@/services/cacheService';
import router from '@/router';

export interface IAppState {
    online: boolean;
    locale: string;
    user?: IUser;
    errors: string[];
    themes: ITheme[];
    themeId: string;
    persist: string[];
}

type AppModule = Module<IAppState, IRootState>;
type AppActionContext = ActionContext<IAppState, IRootState>;

let siteThemeId = Themes.MatchTenantFromUrl();

// STATE
const state: IAppState = {
    online: navigator.onLine,
    locale: localeService.defaultLanguage(),
    user: undefined,
    errors: [],
    themes: Themes.themes,
    themeId: siteThemeId,
    persist: ['themeId'],
};

// GETTERS
const getters = {
    locale: (appState: IAppState) => appState.locale,
    userHasPassword: (appState: IAppState) => appState.user && appState.user.hasPassword,
    userIdentityVerified: (appState: IAppState) => appState.user && appState.user.identityVerified,
    userName: (appState: IAppState) => appState.user && appState.user.name,
    theme: (appState: IAppState) => appState.themes.find(t => t.id === appState.themeId) || Themes.themeDefault,
    browserIsIosOrMacOs: (appState: IAppState) => /iPad|iPhone|iPod|Mac OS X/.test(navigator.userAgent),
};

// MUTATIONS
const mutations = {
  setOnline: (appState: IAppState, online: boolean) => appState.online = online,
  setUser: (appState: IAppState, user: IUser) => appState.user = user,
  setUserPassword: (appState: IAppState) => {
    if (appState.user) {
      appState.user.hasPassword = true;
    }
  },
  changeLocale: (appState: IAppState, locale: string) => {
    if (localeService.isValid(locale)) {
      localStorage.setItem('locale', locale);
      appState.locale = locale;
      i18n.locale = locale;
      moment.locale(locale);
      const html = document.documentElement;
      html.setAttribute('lang', locale);
    }
  },
  navigateToLandingPage: (appState: IAppState) => {
    // const themesWithBooking = ['meneva', 'kovanen', 'fixutaxi', 'tatkela', 'fcg', 'kulkukeskus', 'keskustaksi', 'th'];

    const themesWithBooking = appState.themes.filter(t => t.booking).map(t => t.id);

    if (themesWithBooking.indexOf(appState.themeId) >= 0) {
      router.push({ name: 'booking' });
    } else {
      router.push({ name: 'passengers' });
    }
  },
  clearUserData: (appState: IAppState) => {
    appState.user = undefined;
  },
  error: (appState: IAppState, error: string) => {
    appState.errors.push(error);
  },
  setThemeId: (appState: IAppState, themeId: string) => {
    appState.themeId = themeId;
    if (document.location.host.indexOf('localhost') > -1 || document.location.host.indexOf('.ngrok.io')) {
      // Only used in development

      // Save selected themeId to localStorage, for including it in the XHR header 
      // (checked in backend GetTenant() -> tenantSlugFromHeader)
      if (themeId && themeId !== "default"){
        localStorage.setItem('themeId', themeId);
      } else {
        // No themeId set or using 'default' theme, remove storage item
        localStorage.removeItem('themeId');
      }

      // Save selected themeId to a cookie, for use in the initial loading 
      // (checked in backend GetTenant() -> tenantSlugFromDevCookie)
      if (themeId && themeId !== "default"){
        let d = new Date();
        d.setTime(d.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = "dev-rideit-tenant=" + themeId + ";" + expires + ";path=/";
      } else {
         // No themeId set or using 'default' theme, remove cookie
        let expires = "expires=" + "Thu, 01 Jan 1970 00:00:00 UTC";
        document.cookie = "dev-rideit-tenant=" + "" + ";" + expires + ";path=/";
      }
    }
  },
};

// ACTIONS
const actions = {
  initializeApp: async ({rootGetters, dispatch}: {rootGetters: any, dispatch: any}) => {
    await dispatch('auth/refreshAccessToken', null, { root: true });
    const isAuthenticated = rootGetters['auth/isAuthenticated'];
    if (isAuthenticated) {
      await dispatch('auth/updateRefreshTokenTimeout', null, { root: true });
      await dispatch('initializeUserData');
      await dispatch('passenger/initializePassengers', null, { root: true });
      await dispatch('message/initializeMessages', null, { root: true });
      dispatch('passenger/fetchTrips', null, { root: true });
    }
  },
  initializeUserData: async ({commit, dispatch}: {commit: any, dispatch: any}) => {
    const user = await restService.auth.getUserData();
    commit('setUser', user);
    return user;
  },
  clearUserData: ({commit}: {commit: any}) => {
    commit('clearUserData', null);
    commit('trip/clearUserData', null, { root: true });
    commit('passenger/clearUserData', null, { root: true });
    commit('message/clearUserData', null, { root: true });
    commit('notice/clearUserData', null, { root: true });

    // Delete all cached api calls
    cacheService.clearApiCache();
  },
  updateLogin: async ({commit}: {commit: any}, login: IUsernameAndPassword) => {
    await restService.auth.updateLogin(login.username, login.password);
    commit('setUserPassword');
  },
  changePassword: async ({commit}: {commit: any}, login: IUsernameAndPassword) => {
    await restService.auth.changePassword(login.username, login.password, login.currentPassword);
    commit('setUserPassword');
  },
  setThemeId: (context: AppActionContext, themeId: string) => {
    context.commit('setThemeId', themeId);
  },
};

const appStore: AppModule = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
export default appStore;
