





























































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { BookingStepLoading } from "./models/BookingStep";
import QuotaChip from "./QuotaChip.vue";

@Component({
  components: {
    QuotaChip,
  },
})
export default class BookingStepSelectQuota extends Vue {
  @Prop()
  order!: IBookingOrder;
  @Prop()
  items!: IBookingQuota[];
  @Prop()
  loading!: BookingStepLoading;
  @Prop({default: true}) 
  hasPrevStep!: boolean;
}
