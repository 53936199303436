export default class NavigationItems {

  static accessibilityReport: INavigationItem = {
    url: '/accessibility-report',
    icon: '$fas-fa-file-medical',
    text: 'menu.accessibility-report', 
    requireAuth: false,
  }

  static passengers: INavigationItem  = {
    url: '/passengers',
    icon: '$fas-fa-users',
    text: 'menu.passengers',
    requireAuth: true,
  };

  static passengerTransportationPermissions: INavigationItem  = {
    url: '/transportationPermissions',
    icon: '$fas-fa-file',
    text: 'menu.passengersTransportationPermissions',
    requireAuth: true,
  };

  static trips: INavigationItem = {
    url: '/trips',
    icon: '$fas-fa-taxi',
    // icon: '$fas-fa-taxi',
    text: 'menu.trips',
    requireAuth: true,
  };

  static cancelTrips: INavigationItem = {
    url: '/canceltrips',
    icon: '$fas-fa-times-circle',
    text: 'menu.canceltrips',
    requireAuth: true,
  };

  static booking: INavigationItem = {
    url: '/booking',
    icon: '$fas-fa-credit-card',
    text: 'menu.booking',
    requireAuth: true,
  };

  static personalInfo: INavigationItem = {
    url: '/personal-info',
    icon: '$fas-fa-user',
    text: 'menu.personal-info',
    requireAuth: true,
  };

  static messages: INavigationItem = {
    url: '/messages',
    icon: '$fas-fa-envelope',
    text: 'menu.messages',
    requireAuth: true,
  };

  static privacy: INavigationItem = {
    url: '/privacy',
    icon: '$fas-fa-user-secret',
    text: 'menu.privacy_policy',
    requireAuth: false,
  };

  static register: INavigationItem = {
    url: '/register-travel-card',
    icon: '$fas-fa-id-card',
    text: 'menu.register-travel-card',
    requireAuth: false,
  };

  static createPassword: INavigationItem = {
    url: '/password',
    icon: '$fas-fa-sign-out-alt',
    text: 'menu.create-password',
    requireAuth: true,
  };

  static changePassword: INavigationItem = {
    url: '/password',
    icon: '$fas-fa-unlock-alt',
    text: 'menu.change-password',
    requireAuth: true,
  };

  static logout: INavigationItem = {
    url: '/logout',
    icon: '$fas-fa-sign-out-alt',
    text: 'menu.logout',
    requireAuth: true,
  };

}
