























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ITheme from '../themes/ITheme';
import { IAppState } from '../store/app-store';
import Themes from '../themes';

@Component
  export default class LocaleDropdown extends Vue {
    locales: string[] = ['en', 'fi', 'sv'];

    changeLocale(locale: string) {
      this.$store.commit('app/changeLocale', locale);
    }

    get currentLocale() {
      return this.appState.locale;
    }

    get appState(): IAppState {
      return this.$store.state.app;
    }

    get themes(): ITheme[] {
      return this.appState.themes;
    }

    get theme(): ITheme {
      const theme = this.themes.find(t => t.id === this.themeId);
      return theme ? theme : Themes.themeDefault;
    }

    get themeId() {
        return this.appState.themeId;
    }
  }
