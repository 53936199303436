

































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import moment from "moment";
import restService from "@/services/restService";
import DateHelper from "@/helpers/DateHelper";

@Component({
  components: {},
})
export default class CancelTripsView extends Vue {
  currentStep: number = 1;
  startDateOpen: boolean = false;
  endDateOpen: boolean = false;

  multipleDates: boolean = false;
  minDate: string = new Date().toISOString().substr(0, 10);
  startDate: string = new Date().toISOString().substr(0, 10);
  endDate: string = new Date().toISOString().substr(0, 10);

  startIsoDateIsValid: boolean = true;
  endIsoDateIsValid: boolean = true;

  selectedPassengers: string[] = [];

  selectedTrips: ITrip[] = [];
  headers = [
    { text: "Henkilö", value: "passenger.name", align: "center" },
    { text: "Lähtö", value: "startDateTime" },
    { text: "Matkustaja", value: "name" },
  ];

  cancelreason: string = "";
  reasonRules: any[] = [this.notEmpty];
  reasonIsValid: boolean = false;

  creatingCancellations: boolean = false;
  cancellationError: boolean = false;

  get rules(): any {
    return {
      dateIsoFormat: (v: string) =>
        !!this.isoDateIsValid(v) || this.$t("general.check_date_format"),
      startIsoDateMinDate: (v: string) =>
        !!this.isoDateIsAtOrAfterIsoDate(v, this.minDate) ||
        this.$t("general.date_cannot_be_before_date", {
          date: DateHelper.isoDateToFinnishDate(this.minDate),
        }),
      endIsoDateMinDate: (v: string) =>
        !!this.isoDateIsAtOrAfterIsoDate(v, this.startDate) ||
        this.$t("general.date_cannot_be_before_date", {
          date: DateHelper.isoDateToFinnishDate(this.startDate),
        }),
    };
  }

  @Watch("startDate") 
  onStartDateChanged(value: string, oldVal: string) {
    // If start date is changed to be after end date, set end date to be the same as start
    if (new Date(value) > new Date(this.endDate)) {
      this.endDate = value;
    }
  }

  @Watch("currentStep")
  onCurrenStepChanged(newVal: number, oldVal: number) {
    if (newVal == null || newVal === oldVal || oldVal == null) {
      return;
    }
    this.$nextTick(() => {
      const stepTitleRef = this.$refs.stepTitleRef as HTMLElement;
      if (stepTitleRef) {
        stepTitleRef.focus();
      }
    });
  }

  get startIsoDate(): string {
    return this.startDate;
  }
  set startIsoDate(val: string) {
    this.startIsoDateIsValid =
      this.isoDateIsValid(val) &&
      this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
    if (!this.startIsoDateIsValid) {
      console.warn("set startIsoDate(): startIsoDateIsValid is not valid")
      return;
    }
    this.startDate = DateHelper.finnishDateToIsoDate(val) as string;
  }
  @Watch("startIsoDate")
  startIsoDateChanged(val: string){
    this.startIsoDateIsValid = 
      this.isoDateIsValid(val) && 
      this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
  }
  onStartIsoDateInput(val: string) {
    this.startIsoDateIsValid =
      this.isoDateIsValid(val) &&
      this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
    if (this.startDateIsValid){
      this.startDate = val;
    };
  }

  get endIsoDate(): string {
    return this.endDate;
  }
  set endIsoDate(val: string) {
    this.endIsoDateIsValid =
      this.isoDateIsValid(val) &&
      this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
    if (!this.endIsoDateIsValid) {
      console.warn("set startIsoDate(): startIsoDateIsValid is not valid")
      return;
    }
    this.endDate = DateHelper.finnishDateToIsoDate(val) as string;
  }
  @Watch("endIsoDate")
  endIsoDateChanged(val: string){
    this.endIsoDateIsValid = 
      this.isoDateIsValid(val) && 
      this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
  }
  onEndIsoDateInput(val: string) {
    this.endIsoDateIsValid =
      this.isoDateIsValid(val) &&
      this.isoDateIsAtOrAfterIsoDate(val, this.minDate);
    if (this.endDateIsValid){
     this.endDate = val;
    };
  }

  // Generic date validation
  isoDateIsAtOrAfterIsoDate(isoDate: string, otherIsoDate: string){
    return !moment(isoDate).isBefore(moment(otherIsoDate));
  }

  isoDateIsValid(val: string): boolean {
    const isIsoDate = DateHelper.isIsoDate(val);
    if (!isIsoDate){
      console.warn("isoDateIsValid:" + " cannot convert to iso date: " + val);
      return false;
    }
    const valMoment = moment(val);
    if (!valMoment.isValid()){
      console.warn("isoDateIsValid:" + " moment isValid=false: " + valMoment);
      return false;
    }
    return true;
  }

  get startDateIsValid(): boolean {
    return this.startIsoDateIsValid;
  }
  get endDateIsValid(): boolean {
    return this.endIsoDateIsValid;
  }

  get locale() {
    return this.$store.getters["app/locale"];
  }

  get startDateLabel() {
    if (this.multipleDates) {
      return this.$t("general.start");
    } else {
      return this.$t("general.date");
    }
  }

  get selectedDates() {
    let res = moment(this.startDate).format("L");
    if (this.multipleDates && this.startDate !== this.endDate) {
      res += ` - ${moment(this.endDate).format("L")}`;
    }
    return res;
  }

  get tripsInTimeFrame(): ITrip[] {
    const min = new Date(this.startDate);
    min.setHours(0, 0, 0, 0);
    const max = this.multipleDates
      ? new Date(this.endDate)
      : new Date(this.startDate);
    max.setDate(max.getDate() + 1);

    const trips = this.$store.state.trip.trips
      .filter((t: ITrip) => !t.cancelledAt)
      .filter((t: ITrip) => {
        const startTime = new Date(t.startDateTime || t.endDateTime).getTime();
        return min.getTime() <= startTime && startTime < max.getTime();
      });

    return trips;
  }

  filter: any = null;

  get tripDates(): string[] {
    return this.$store.getters["trip/dates"];
  }

  dateColor(date: string) {
    const trips = this.$store.getters["trip/trips"](date, this.filter)
      .filter((t: ITrip) => !t.cancelledAt)
    const colors = trips
      .map((t: any) => this.$randomColor(t.passenger.id))
      .filter((v: any, i: number, a: any) => a.indexOf(v) === i);
    if (colors.length > 1) {
      return "primary";
    } else if (colors.length === 1) {
      return colors[0];
    }
  }

  get browserIsIosOrMacOs(): boolean {
    return this.$store.getters["app/browserIsIosOrMacOs"]
  }

  get platformSpecificSwitchType(): string {
    return this.browserIsIosOrMacOs
      ? "switch"
      : "checkbox";
  }

  get passengers(): IPassenger[] {
    let passengers = this.$store.getters["passenger/passengers"];
    const passengersInTrips: IPassenger[] = this.tripsInTimeFrame.map(
      (trip) => trip.passenger
    );
    // remove passengers that have no trips for selected timeframe.
    passengers = passengers.filter(
      (p: IPassenger) =>
        passengersInTrips.map(({ id }) => id).indexOf(p.id) > -1
    );
    return passengers;
  }

  get passengerNames() {
    return this.passengers.map((p) => p.name).join(", ");
  }

  get trips() {
    const trips = this.tripsInTimeFrame.filter(
      (t) => this.selectedPassengers.indexOf(t.passenger.id) >= 0
    );
    return trips;
  }

  get steps(): any[] {
    let steps = [
      {
        id: 1,
        title: this.$t("canceltrips.choose_dates"),
      }
    ];
    if (this.passengers.length > 1){
      steps.push({
        id: 2,
        title: this.$t("canceltrips.choose_passengers"),
      });
    }
    steps = steps.concat([
      {
        id: 3,
        title: this.$t("canceltrips.choose_trips"),
      },
      {
        id: 4,
        title: this.$t("canceltrips.create_cancellation"),
      },
      {
        id: 5,
        title: this.$t("canceltrips.creating_cancellations"),
      },
    ]);
    return steps;
  }

  get currentStepNumber(): number {
    return this.steps.findIndex((s) => s.id === this.currentStep) + 1;
  }

  get currentStepTitle(): string {
    let step = this.steps.find((s) => s.id === this.currentStep);
    return step ? step.title : "TODO: StepTitleWhenNoStepFound";
  }

  nextStep(stepNumber: number) {
    switch (stepNumber) {
      case 2: {
        if (this.passengers.length === 0) {
          return;
        }
        if (this.passengers.length === 1) {
          // skip the step forwards
          this.selectedPassengers = [this.passengers[0].id];
          stepNumber++;
        }
        break;
      }
      case 3: {
        if (this.selectedPassengers.length === 0) {
          return;
        }
        break;
      }
      case 4: {
        if (this.selectedTrips.length === 0) {
          return;
        }
        break;
      }
    }
    this.currentStep = stepNumber;
  }

  prevStep(stepNumber: number) {
    switch (stepNumber) {
      case 2: {
        if (this.passengers.length === 1) {
          // skip the step backwards
          stepNumber--;
        }
        break;
      }
    }
    this.currentStep = stepNumber;
  }

  async createCancellations() {
    this.cancellationError = false;
    this.nextStep(5);
    this.creatingCancellations = true;

    const cancellation: ITripCancellation = {
      reason: this.cancelreason.trim(),
      trips: this.selectedTrips,
    };
    try {
      await this.$store.dispatch("trip/cancelTrips", cancellation);
    } catch (err){
      this.creatingCancellations = false;
      this.cancellationError = true;
      return;
    }
    // Clear selection after success to avoid double cancellations
    this.selectedPassengers = [];
    this.selectedTrips = [];
    this.creatingCancellations = false;
  }

  created() {
    // Select all passengers by default when view is opened
    // this.selectedPassengers = this.passengers.map((p) => p.id);
    if (this.passengers.length >= 1){
      this.selectedPassengers = [this.passengers[0].id];
    }
  }

  passengerSelected(passenger: IPassenger) {
    if (!passenger){
      return;
    }
    return this.selectedPassengers.indexOf(passenger.id) >= 0;
  }

  togglePassengerSelection(passenger: IPassenger) {
    const index = this.selectedPassengers.indexOf(passenger.id);
    if (index >= 0) {
      this.selectedPassengers.splice(index, 1);
      // If some of the passengers trips were selected, remove selection
      this.selectedTrips = this.selectedTrips.filter(
        (t) => this.selectedPassengers.indexOf(t.passenger.id) >= 0
      );
    } else {
      this.selectedPassengers.push(passenger.id);
    }
  }

  toggleTripSelection(trip: ITrip) {
    const index = this.selectedTrips.indexOf(trip);
    if (index >= 0) {
      this.selectedTrips.splice(index, 1);
    } else {
      this.selectedTrips.push(trip);
    }
  }

  tripSelected(trip: ITrip) {
    return this.selectedTrips.indexOf(trip) >= 0;
  }

  mustChooseOne(value: any) {
    if (!Array.isArray(value) || value.length === 0) {
      return this.$t("error.choose_at_least_one");
    }
    return true;
  }

  notEmpty(value: any) {
    if (!value || /^\s*$/.test(value)) {
      return this.$t("error.must_not_be_empty");
    }
    return true;
  }

  reset() {
    this.currentStep = 1;
  }

  mounted() {
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
  }
}
