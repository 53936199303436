


























































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { TranslateResult } from 'vue-i18n';

@Component
export default class Password extends Vue {

  minPasswordLength = 6;

  userName: string = '';
  currentPassword: string = '';
  newPassword: string = '';
  confirmPassword: string = '';
  showPasswords = false;

  valid = true;
  loading = false;
  done = false;

  existingUsernames: string[] = [];
  serverErrors: { 
      currentPassword: string,
      password: string | TranslateResult, 
      userName: string 
    } = { 
      currentPassword: '',
      password: '', 
      userName: '' 
    };

  saveErrors: Array<string | TranslateResult> = [];


  get userNameIsValid(): boolean{
    return this.$refs.userNameRef 
      ? (this.$refs.userNameRef as any).valid
      : false;
  }

  get currentPasswordIsValid(): boolean {
    return this.$refs.currentPasswordRef
      ? (this.$refs.currentPasswordRef as any).valid
      : false;
  }

  get newPasswordIsValid(): boolean {
    return this.$refs.newPasswordRef
      ? (this.$refs.newPasswordRef as any).valid
      : false;
  }

  get confirmPasswordIsValid(): boolean {
    return this.$refs.confirmPasswordRef
      ? (this.$refs.confirmPasswordRef as any).valid
      : false;
  }

  get userHasPassword() {
    return this.$store.getters['app/userHasPassword'];
  }

  get userIdentityVerified() {
    return this.$store.getters['app/userIdentityVerified'];
  }

  get title() {
    if (this.userHasPassword) {
      return this.$t('password.changeTitle');
    } else {
      return this.$t('password.createTitle');
    }
  }

  get locale() {
    return this.$store.state.app.locale;
  }

  get usernameRules() {
    return [this.minUsername, this.maxUsername, this.uniqueName, this.serverUserName];
  }

  get currentPasswordRules() {
    return [this.requiredField, this.serverCurrentPassword];
  }

  get newPasswordRules() {
    return [this.requiredField, this.minPassword, this.serverPassword];
  }

  get confirmPasswordRules() {
    return [this.requiredField, this.passwordsMatch];
  }

  async saveChanges() {
    this.saveErrors = [];
    try {
      this.loading = true;
      await this.$store.dispatch(
        this.userHasPassword ? 'app/changePassword' : 'app/updateLogin' , 
        {
          username: this.userName, 
          currentPassword: this.currentPassword, 
          password: this.newPassword
        }
      );
      this.done = true;
    } catch (error) {
      // TODO: Strong type error
      switch ((error as any).status)
      {
        case 400:
          const data: IValidationError = (error as any).data;
          this.serverErrors.currentPassword = data.errors.filter(e => e.property === 'currentPassword').map(e => e.error).join('');
          this.serverErrors.userName = data.errors.filter(e => e.property === 'userName').map(e => e.error).join('');
          this.serverErrors.password = data.errors.filter(e => e.property === 'password').map(e => e.error).join('');

          // Special handling for required number of unique characters
          const uniqueCharsValidationErrorStart = "Passwords must use at least";
          const uniqueCharsValidationErrorEnd = " different characters.";
          if (
            this.serverErrors.password.startsWith(uniqueCharsValidationErrorStart) && 
            this.serverErrors.password.endsWith(uniqueCharsValidationErrorEnd)
          ){
            const numberOfUniqueCharsRequired = this.serverErrors.password.substring(
              uniqueCharsValidationErrorStart.length, 
              this.serverErrors.password.lastIndexOf(uniqueCharsValidationErrorEnd)
            );
            this.serverErrors.password = this.$t("password.validation.passwordRequiresUniqueChars", { numberOfUniqueCharsRequired });
          }
          // Force-validate the form
          (this.$refs.form as any).validate();
          break;

        case 500:
        default:
          this.saveErrors.push(this.$t("password.errors.genericSaveError"));
          break;
      }
    }
    this.loading = false;
  }

  goBack() {
    this.$router.go(-1);
  }

  a11yValidationIteration = 0;
  a11yValidationIterationString = "";

  onCurrentPasswordFieldInput(){
    this.serverErrors.currentPassword = '';
  }

  onNewPasswordFieldInput(){
    this.refreshA11yValidationPrompts();
  }

  onNewPasswordConfirmationFieldInput(){
    this.refreshA11yValidationPrompts();
  }

  refreshA11yValidationPrompts(){
    this.a11yValidationIteration++;
    this.a11yValidationIterationString = this.a11yValidationIteration % 2 === 0
      ? ""
      : "​"; // this looks like a space, but is an U+200B
  }

  minUsername(v: any) {
    const minChars = 4;
    return v.length >= minChars || this.$t('general.validation.atLeastChars', [minChars]);
  }
  maxUsername(value: string) {
    const maxChars = 256;
    return value.length <= maxChars || this.$t('general.validation.atMostChars', [maxChars]);
  }
  minPassword(v: any) {
    return v.length >= this.minPasswordLength || this.$t('general.validation.atLeastChars', [this.minPasswordLength]) + " " + this.a11yValidationIterationString;
  }
  requiredField(v: any) {
    return !!v || this.$t('password.validation.required') + " " + this.a11yValidationIterationString;
  }
  passwordsMatch(v: any) {
    return v === this.newPassword ? true : this.$t('password.validation.passwordMatch') + " " + this.a11yValidationIterationString;
  }
  uniqueName(v: any) {
    return this.existingUsernames.includes(v) ? this.$t('password.validation.userNameTaken') : true;
  }
  serverUserName(v: any) {
    return !this.serverErrors.userName || this.serverErrors.userName;
  }
  serverPassword(v: any) {
    return !this.serverErrors.password || this.serverErrors.password;
  }
  serverCurrentPassword(v: any) {
    return !this.serverErrors.currentPassword || this.$t('password.validation.currentPasswordIsNotValid');
  }

  @Watch('userName') onUserNameChange(val: any, oldVal: any) {
    this.serverErrors.userName = '';
  }
  @Watch('newPassword') onPasswordChange(val: any, oldVal: any) {
    this.serverErrors.password = '';
  }
  @Watch('locale') onLocaleChange(val: any) {
    if (this.$refs.form) {
      (this.$refs.form as any).validate();
    }
  }
  mounted() {
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
  }
}
