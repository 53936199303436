






































































import { RideitBackend } from '@/services/restService';
import { Component, Vue } from 'vue-property-decorator';
import { IAppState } from '../store/app-store';
import Themes from '../themes';

@Component
export default class Home extends Vue {

  get isAuthenticated() {
    return this.$store.getters['auth/isAuthenticated'];
  }

  get appState(): IAppState {
    return this.$store.state.app;
  }

  get showList() {
    return this.appState.themeId !== Themes.themeMeneva.id;
  }

  get showRegister() {
    return this.appState.themeId === Themes.themeMeneva.id ||
      this.appState.themeId === Themes.themeFixutaxi.id;
  }

  get showImage() {
    return !!this.image;
  }

  get image() {
    if (this.appState.themeId === Themes.themeDefault.id) {
      return require('../../public/img/default/welcome.jpg');
    } else if (this.appState.themeId === Themes.themeMeneva.id) {
      return require('../../public/img/meneva/welcome.jpg');
    }
  }

  login() {
    const url = RideitBackend.baseUrl + 'login?locale=' + this.$i18n.locale;
    window.location.href = url;
  }

  logout() {
    this.$store.dispatch('auth/logout');
  }
}
