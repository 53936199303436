

























































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import restService from '@/services/restService';
import BookingStepSelectPassenger from '@/components/Booking/BookingStepSelectPassenger.vue';
import BookingStepSelectDate from '@/components/Booking/BookingStepSelectDate.vue';
import BookingStepSelectTimeAndAddresses from '@/components/Booking/BookingStepSelectTimeAndAddresses.vue';
import BookingStepEnterAdditionalInfo from '@/components/Booking/BookingStepEnterAdditionalInfo.vue';
import BookingStepSelectQuota from '@/components/Booking/BookingStepSelectQuota.vue';
import BookingStepViewOrderSummary from '@/components/Booking/BookingStepViewOrderSummary.vue';
import { TranslateResult } from 'vue-i18n';
import { INotice } from '../store/notice-store';
import { DestinationSource } from '../components/Booking/models/PlacePickerDestination';
import BookingStep, { BookingStepLoading, BookingStepTypeEnum } from '@/components/Booking/models/BookingStep';

@Component({
  components: {
    BookingStepSelectPassenger,
    BookingStepSelectDate,
    BookingStepSelectQuota,
    BookingStepSelectTimeAndAddresses,
    BookingStepEnterAdditionalInfo,
    BookingStepViewOrderSummary,
  }
})
export default class BookingView extends Vue {

  get steps(): BookingStep[] { 
    let steps = [] as BookingStep[];
    if (this.passengers.length != 1){
      steps.push(
         {
          id: 1,
          type: BookingStepTypeEnum.SelectPassenger,
          title: this.$t('booking.selectPassenger')
        }
      )
    }
    steps = steps.concat(

    [
   
    {
      id: 2,
      type: BookingStepTypeEnum.SelectDate,
      title: this.$t('booking.chooseDate')
    },
    {
      id: 3,
      type: BookingStepTypeEnum.SelectQuota,
      title: this.$t('booking.chooseQuotaType')
    },
    {
      id: 4,
      type: BookingStepTypeEnum.SelectTimeAndAddresses,
      title: this.$t('booking.enterTripInformation')
    },
    {
      id: 5,
      type: BookingStepTypeEnum.EnterAdditionalInfo,
      title: this.$t('booking.enterAdditionalInfo')
    },
    {
      id: 6,
      type: BookingStepTypeEnum.ViewOrderSummary,
      title: this.$t('booking.summary')
    },
    {
      id: 7,
      type: BookingStepTypeEnum.SelectTimeAndAddresses,
      title: this.$t('booking.enterTripInformationReturnTrip')
    },
    {
      id: 8,
      type: BookingStepTypeEnum.EnterAdditionalInfo,
      title: this.$t('booking.enterAdditionalInfoReturnTrip')
    },
    {
      id: 9,
      type: BookingStepTypeEnum.ViewOrderSummary,
      title: this.$t('booking.summaryReturnTrip')
    },
  ]);
  return steps;
  }

  currentStep: BookingStep | null = null;
  bookingQuotas: IBookingQuota[] = [];

  // holding passenger places from movit
  passengerBookingPlacesInfo: IBookingAddressInfo | null = null;
  passengerDestinations: IDestination[] = [];

  dateWithoutTime: string = new Date().toISOString().substr(0, 10);
  bookingPhoneNumber: string = '';

  emptyBooking: IBookingOrder = {
    passenger: null,
    fromAddress: null,
    fromApartment: null,
    viaPoints: [],
    toAddress: null,
    toApartment: null,
    passengerCount: 1,
    dateAndTime: this.$moment.min(
      this.$moment().add(5, 'minutes'), 
      this.$moment().endOf('day')
    ),
    isDepartureTime: BookingStep.departureTimeOptions[0].value,
    withAttendant: false,
    additionalInfo: '',
    quota: null,
    price: 0,
  };

  order: IBookingOrder = Object.assign({}, this.emptyBooking);

  loading: BookingStepLoading = new BookingStepLoading();
  
  bookingSaving = false;
  bookingSaved = false;
  bookingSaveError = '';

  get locale() {
    return this.$store.getters['app/locale'];
  }


  get formattedDate() {
    return this.$moment(this.order.dateAndTime).format('DD.MM.YYYY');
  }

  get formattedDateAndTime() {
    return this.$moment(this.order.dateAndTime).format('DD.MM.YYYY HH:mm');
  }

  get passengers(): IPassenger[] {
    return this.$store.getters["passenger/passengers"];
  }

  get selectedPassengerName() {
    return this.order.passenger ? this.order.passenger.name : '';
  }

  get avatarColor() {
    return this.order.passenger ? this.$randomColor(this.order.passenger.id) : '#ffffff';
  }
  get avatarText() {
    return this.order.passenger ? this.order.passenger.avatarText : '';
  }

  get quotaRecap() {
    if (this.order.quota) {
      let quotaRecap = this.order.quota.name;
      quotaRecap += this.order.quota.decisionType ? ` - ${this.order.quota.decisionType}` : '';
      quotaRecap += this.order.quota.note ? `: ${this.order.quota.note}` : ``;
      return `${quotaRecap}`;
    }
    return '';
  }

  get remainingQouta() {
    if (this.order.quota) {
      return this.order.quota.remainingQuota;
    }
  }

  get rules() {
    return {
      passenger: [() => !!this.order.passenger],
      quota: [() => this.order.passenger ? !!this.order.quota : true ],
      travelInfo: [],
      useTravelInfoRules: false,
      phoneNumber: [
        (val: string) => /^\+(?:[0-9] ?){6,14}[0-9]$/.test(val || ''),
        (val: string) => val !== '+358',
      ],
      timeWithoutDate: [ (val: string) => !!val],
      fromAddress: [
        () => this.order.fromAddress ? true : false,
        () => {
          if (this.order.fromAddress && this.order.toAddress) {
            const lat = this.order.fromAddress.latitude === this.order.toAddress.latitude;
            const lon = this.order.fromAddress.longitude === this.order.toAddress.longitude;
            return lat && lon ? this.$t('booking.validation.locationSame') : true;
          }
          return true;
        },
        // Note: Validation for missing street number removed (#5941)
        // () => {
        //   if (this.order.fromAddress && this.order.fromAddress.source === DestinationSource.GooglePlaceResult) {
        //     return this.order.fromAddress.streetnumber ? true : this.$t('booking.validation.streetNumberRequired');
        //   }
        //   return true;
        // },
      ],
      viaAddress: [
        (viaPoint: IDestination) => {
          return viaPoint ? true : false;
        },
        (viaPoint: IDestination) => {
          const startPlace = this.order.fromAddress;
          const endPlace = this.order.toAddress;
          if(viaPoint && startPlace && endPlace) {
            return (startPlace.latitude === viaPoint.latitude && startPlace.longitude === viaPoint.longitude) ||
              (endPlace.latitude === viaPoint.latitude && endPlace.longitude === viaPoint.longitude)
             ? this.$t('booking.validation.viaPointLocationSame') : true;
          }
          return true
        },
        (viaPoint: IDestination) => {
          if(this.order.viaPoints.length > 1) {
            let hits = this.order.viaPoints.filter(v => v.viaPointAddress?.longitude === viaPoint.longitude &&
             v.viaPointAddress.latitude === viaPoint.latitude);

             return hits.length > 1 ? this.$t('booking.validation.viaPointSameAsOtherViaPoint') : true;
          }
          return true;
        } 
      ],
      toAddress: [
        () => this.order.toAddress ? true : false,
        () => {
          if (this.order.fromAddress && this.order.toAddress) {
            const lat = this.order.fromAddress.latitude === this.order.toAddress.latitude;
            const lon = this.order.fromAddress.longitude === this.order.toAddress.longitude;
            return lat && lon ? this.$t('booking.validation.locationSame') : true;
          }
          return true;
        },
        // Note: Validation for missing street number removed (#5941)
        // () => {
        //   if (this.order.fromAddress && this.order.fromAddress.source === DestinationSource.GooglePlaceResult) {
        //     return this.order.fromAddress.streetnumber ? true : this.$t('booking.validation.streetNumberRequired');
        //   }
        //   return true;
        // },
      ],
      passengerCount: [() => this.order.passengerCount >= 1 ? true : this.$t('booking.validation.passengerCount', [1])],
    };
  }

  onResetClick(){
    this.resetBooking();
    this.currentStep = this.steps[0];
  }

  resetBooking(){
    this.order = Object.assign({}, this.emptyBooking);
    this.order.viaPoints = [];
    this.bookingPhoneNumber = '';
    this.bookingSaved = false;
    this.bookingSaveError = '';
  }

  onPlaceChange(){
    this.$nextTick(() => {
      const bookingFormRef = this.$refs.bookingFormRef;
      if (bookingFormRef){
        if (this.order.fromAddress && this.order.toAddress){
          (bookingFormRef as any).validate();
        }
      };
    });
  }

  onDateWithoutTimeChange(val: string) {
    this.dateWithoutTime = val;
    const myDate = new Date(this.dateWithoutTime);
    const day = myDate.getDate();
    const month = myDate.getMonth() + 1;
    const year = myDate.getFullYear();

    this.onDayChange(day);
    this.onMonthChange(month);
    this.onYearChange(year);
    this.updatePassengerQuotas();
  }

  @Watch('currentStep') 
  onCurrenStepChanged(newVal: BookingStep, oldVal: BookingStep | null) {
    if (newVal == null || newVal === oldVal || oldVal == null){
      return;
    }
    this.$nextTick(() => {
      const stepTitleRef = (this.$refs.stepTitleRef as HTMLElement);
      if (stepTitleRef){
        stepTitleRef.focus();
      }
    });
  }

  onMinuteEnterKeyup() {
    const isDepartureTimeSwitchElem = document.querySelector('#is-departure-time-switch');
    if (isDepartureTimeSwitchElem) {
      (isDepartureTimeSwitchElem as HTMLInputElement).focus();
    }
  }

  onDayChange(date: number) {
    const updatedTime = this.$moment(this.order.dateAndTime);
    updatedTime.set({ date });
    this.order.dateAndTime = updatedTime;
  }

  onMonthChange(month: number) {
    const updatedTime = this.$moment(this.order.dateAndTime);
    updatedTime.set({ month: month - 1 });
    this.order.dateAndTime = updatedTime;
  }

  onYearChange(year: number) {
    const updatedTime = this.$moment(this.order.dateAndTime);
    updatedTime.set({ year });
    this.order.dateAndTime = updatedTime;
  }

  async onPassengerChanged(val: IPassenger | null) {
    this.order.passenger = val;
    this.order.fromAddress = null;
    this.order.toAddress = null;
    this.bookingPhoneNumber = '';

    if (!val){
      return;
    }
    await this.fetchPassengerBookingPlacesInfo();
    await this.updatePassengerQuotas();
  }

  async updatePassengerQuotas() {
    if (this.order.passenger) {
      this.loading.quotas = true;
      this.bookingQuotas = await restService.quotas.get(this.order.passenger.id, this.dateWithoutTime);
      if (this.order.quota) {
        // Reselect the same quota that was previously selected (from new objects)
        this.order.quota = this.bookingQuotas.find(q => q.id === this.order.quota!.id) || null;
      } else if (this.bookingQuotas.length >= 1){
        const availableQuotas = this.bookingQuotas.filter(q => q.remainingQuota > 0);
        if (availableQuotas.length >= 1){
          this.order.quota = availableQuotas[0];
        }
      }
      this.loading.quotas = false;
    } else {
      this.bookingQuotas = [];
    }
    // this.quotaEditable = this.bookingQuotas.length !== 1;
  }

  get currentStepTitle(): TranslateResult {
    let step = this.steps.find(s => s.id === this.currentStep?.id);
    return step
      ? step.title
      : "TODO: StepTitleWhenNoStepFound";
  }

  get currentStepIndex(){
    const currentStepIndex = this.steps.findIndex(s => s.id === this.currentStep?.id);
    if (currentStepIndex < 0 || currentStepIndex >= this.steps.length){
      return -1;
    }
    return currentStepIndex;
  }

  get currentStepNumber(){
    return this.currentStepIndex + 1;
  }

  nextStep(){
    if (this.currentStepIndex < 0){
      console.warn("Booking: prevStep: currentStepIndex is below 0")
      return;
    }
    this.currentStep = this.steps[this.currentStepIndex + 1];
  }

  gotoReturnBookingStep(){
    if (this.currentStepIndex < 0){
      console.warn("Booking: prevStep: currentStepIndex is below 0")
      return;
    }

    // Reset state  
    this.bookingSaving = false;
    this.bookingSaved = false;
    this.bookingSaveError = "";

    // Switch from and to addresses
    var originalFromAddress = this.order.fromAddress;
    var originalFromApartment = this.order.fromApartment;
    this.order.fromAddress = this.order.toAddress;
    this.order.fromApartment = this.order.toApartment;
    this.order.toAddress = originalFromAddress;
    this.order.toApartment = originalFromApartment;

    // Fast forward time for additional x hours
    const lastMinuteOfDay = this.$moment(this.order.dateAndTime).endOf('day');
    this.order.dateAndTime = this.$moment.min(
      this.$moment(this.order.dateAndTime).add(1, 'hour'), 
      lastMinuteOfDay
    );

    // Clear via points
    this.order.viaPoints = [];

    // Move to the return trip info adding step
    this.currentStep = this.steps[this.currentStepIndex + 1];
  }
  
  prevStep(){
    if (this.currentStepIndex < 0){
      console.warn("Booking: prevStep: currentStepIndex is below 0")
      return;
    }
    this.bookingSaveError = '';
    this.currentStep = this.steps[this.currentStepIndex - 1];
  }


  async createBooking() {
    this.bookingSaving = true;
    this.bookingSaveError = '';
    try {
      const order = this.convertOrderToBooking(this.order);
      const result = await restService.booking.postBooking(order);
      // Fetch trips for the passenger if booking was automatic
      await this.$store.dispatch('passenger/getPassengerTrips', this.order.passenger);

      // Display notice
      // TODO: A11y: Snackbar notice is read twice on NVDA, this can be re-enabled once fixed
      // const notice: INotice = {
      //   text: 'booking.bookingSubmittedSuccessfully',
      //   color: 'success',
      //   link: '/trips',
      //   button: 'booking.move_to_trips',
      // };
      // this.$store.commit('notice/showNotice', notice);
      this.bookingSaved = true;
    } catch (error) {
      // TODO: Strong type error
      let formattedErrorMessage = null;
      let genericErrorMessage: string = this.$t("booking.failed").toString();
      let specificErrorMessage = (error as any).data && (error as any).data.message
        ? (error as any).data.message
        : null;
      if ((error as any).status === 400 && specificErrorMessage) {
        if (specificErrorMessage.includes('municipalit')){
          specificErrorMessage = this.$t("booking.error_municipality");
        }
        formattedErrorMessage = genericErrorMessage + ': ' + specificErrorMessage;
      }

      if (!formattedErrorMessage){
        formattedErrorMessage = genericErrorMessage;
      }
      // TODO: A11y: Snackbar notice is read twice on NVDA, this can be re-enabled once fixed
      // const notice: INotice = {
      //   text: errorMessage,
      //   color: 'error',
      //   link: '/booking',
      //   button: 'general.button.back',
      // };
      // this.$store.commit('notice/showNotice', notice);
      // For now, using a component-defined error message layout
      this.bookingSaveError = formattedErrorMessage;
      this.bookingSaved = false;
    }
    this.bookingSaving = false;
  }

  convertOrderToBooking(order: IBookingOrder) {
    const booking: IBooking = {
      transportationPermissionId: order.quota ? order.quota.id : undefined,
      passengers: order.passenger ? [ this.convertToBookingPassenger(order.passenger) ] : [],
      departureDateTime: order.isDepartureTime ? order.dateAndTime : '',
      arrivalDateTime: order.isDepartureTime ? '' : order.dateAndTime,
      type: order.quota ? order.quota.name : '',
      destinations: [],
     
      additionalInformation: this.order.additionalInfo,
      attributes: this.order.quota
        ? this.order.quota.extras.filter(e => e.selected).map(e => e.extraId).join('')
        : '',
    };

    if(order.viaPoints.length > 0) {
      booking.destinations = order.viaPoints
      .filter(v => v.viaPointAddress)
      .map(v => {
        const viaPoint = this.addApartmentToAddress(v.viaPointApartment, v.viaPointAddress!)
        viaPoint.isViaPoint = true;
        viaPoint.waitTime = v.waitTime;
        return viaPoint;
      })
    }
    if(order.fromAddress && order.toAddress) {
      booking.destinations.push(this.addApartmentToAddress(order.toApartment, order.toAddress));
      booking.destinations.unshift(this.addApartmentToAddress(order.fromApartment, order.fromAddress));
    }

    return booking;
  }

  convertToBookingPassenger(p: IPassenger): IBookingPassenger {
    const id = this.order.passenger?.id || '';
    const name = this.order.passenger?.name || '';
    const phone = this.bookingPhoneNumber || '';
    const passengerCount = this.order.passengerCount;

    return { id, name, phone, email: '', passengerCount };
  }

  convertToDestinations(destinations: IPassengerDestination[]): IDestination[] {
    const result: IDestination[] = [];

    destinations.forEach(dest => {
      result.push(
        {
          id: dest.id,
          alias: dest.alias,
          display: dest.display,
          address: dest.streetAddress,
          city: dest.city,
          postalcode: dest.postalcode,
          latitude: dest.latitude,
          longitude: dest.longitude,
          isHome: dest.isHome,
          isViaPoint: dest.isViaPoint,
          isPickup: dest.isPickup,
          isMain: dest.isMain,
        });
    });
    return result.filter(d => d);
  }

  addApartmentToAddress(apartment: string | null, destination: IDestination) {
    const destinationCopy = { ...destination };
    if (!destination.isHome && !destination.isMain && apartment) {
      const address = destination.address.toLowerCase().replace(/\s/g, '');
      const apartmentCleaned = apartment.toLowerCase().replace(/\s/g, '');
      if (address.indexOf(apartmentCleaned) !== address.length - apartmentCleaned.length) {
        destinationCopy.address = [destinationCopy.address, apartmentCleaned].filter(x => x).join(' ');
      }
    }
    return destinationCopy;
  }

 async fetchPassengerBookingPlacesInfo() {
    if (this.order.passenger) {
      const bookingPlaces = await restService.passenger.getPassengerPlaces(
        this.order.passenger ? this.order.passenger.id : '');
      this.passengerBookingPlacesInfo = bookingPlaces;
      this.passengerDestinations = this.convertToDestinations(bookingPlaces.addresses);
    }
  }

  created() {
    (window as any).__book = this;
    if (this.passengers.length === 1){
      this.order.passenger = this.passengers[0];
    }
  }

  mounted() {
    if (this.currentStep == null){
      this.currentStep = this.steps[0];
    }
    // #5929 forbids programmatic focus on page change
    // (this.$refs.pageTitleRef as HTMLElement).focus();
  }

}
