















































































































































































































































































































































import moment, { Moment } from "moment-timezone";
import { Component, Vue, Watch } from "vue-property-decorator";
import restService from "../services/restService";
import TripTrackingStatus, {
  TripStatusCode,
} from "@/components/TripTrackingStatus.vue";
import ITheme from "@/themes/ITheme";
import Themes from "@/themes";
import TripMap from "@/components/Map/TripMap.vue";

@Component({
  components: {
    TripMap,
    TripTrackingStatus,
  },
  async beforeRouteEnter(to, from, next) {
    // TODO: MAKE IT BETTER
    if (to.name === "track") {
      const trip = await restService.trips.tracked(to.params.link);
      const names = trip.passenger.name.split(" ");
      const letters = names.filter((x) => x).map((n) => n[0].toUpperCase());
      letters.length = letters.length > 2 ? 2 : letters.length;
      trip.passenger.avatarText = `${letters.join("")}`;
      // TODO: What's this, how can this be done with this.$randomColor(trip.passenger.id)?
      // trip.passenger.avatarColor = 'primary';
      next(async (instance: any) => {
        const trackingLink = {
          link: to.params.link,
          id: trip.id,
          date: trip.startDateTime.split("T")[0],
        };
        instance.trackingLink = trackingLink;
        instance.$store.commit("trip/addOrReplaceTrip", trip);
      });
    } else {
      // Normal trips enter without hacks
      next();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.name === "track") {
      const trip = await restService.trips.tracked(to.params.link);
      const trackingLink = {
        link: to.params.link,
        id: trip.id,
        date: trip.startDateTime.split("T")[0],
      };
      (this as any).trackingLink = trackingLink;
      this.$store.commit("trip/addOrReplaceTrip", trip);
    }
  },
})
export default class TripsEdit extends Vue {
  static readonly QUERY_CUTOFF_MINUTES = 30;
  static readonly QUERY_INCREASE_MINUTES = 15;
  static readonly QUERY_FAST_INTERVAL = 10 * 1000;
  static readonly QUERY_DEFAULT_INTERVAL = 60 * 1000;

  dialog = false;
  cancelReason: string = "";
  cancelling: boolean = false;
  reviewing: boolean = false;
  cancelformvalid: boolean = false;
  reviewformvalid: boolean = false;
  reviewComment: string = "";
  driverStars: number = 0;
  experienceStars: number = 0;
  reviewGiven: boolean = false;
  trackingLink: { link: string; id: number; date: string } | null = null;
  tripStatusCode = TripStatusCode;

  trackingInterval: any = null;
  trackingStartFastQueryTimeout: any;
  trackingEndFastQueryTimeout: any;

  statusInterval: any = null;
  statusStartFastQueryTimeout: any;
  statusEndFastQueryTimeout: any;

  tripCompletionInterval: any;
  tripIsCompleted: boolean = false;

  reasonRules: any[] = [this.notEmpty];

  cancelTripDialogOpen: boolean = false;

  notEmpty(value: any) {
    if (!value || /^\s*$/.test(value)) {
      return this.$t("error.must_not_be_empty");
    }
    return true;
  }

  get theme(): ITheme {
    return this.$store.getters["app/theme"];
  }

  get showTrackingStatus() {
    const shouldQueryTrip =
      this.$route.name === "track" ||
      (this.dialogTrip && this.dialogTrip.allowEtaQuery);

    if (!shouldQueryTrip) {
      return false;
    }

    return (
      this.dialogTrip.status &&
      this.dialogTrip.status.code !== TripStatusCode.Unknown &&
      this.dialogTrip.status.code !== TripStatusCode.Cancelled
    );
  }

  get showTripMap() {
    if (!this.dialogTrip || !this.showTrackingStatus) {
      return false;
    }

    const addresses = [
      this.dialogTrip.startAddress,
      this.dialogTrip.endAddress,
    ];

    return addresses.some((a) => a.lat && a.lng);
  }

  get canBeCancelled(): boolean {
    // Currently tracked trips cannot be cancelled
    if (this.$route.name === "track") {
      return false;
    }

    const tomorrow = moment().startOf("day");
    const tripDate = moment(
      this.dialogTrip.startDateTime || this.dialogTrip.endDateTime
    ).startOf("day");
    if (tripDate < tomorrow) {
      return false;
    }

    if (
      this.dialogTrip &&
      this.dialogTrip.status &&
      this.isFinalTripStatus(this.dialogTrip.status.code)
    ) {
      return false;
    }

    const hasStarted = this.dialogTrip.pickupTime || this.dialogTrip.noShowTime;
    return !hasStarted && !this.dialogTrip.cancelledAt;
  }

  get showReview(): boolean {
    if (
      this.dialogTrip &&
      (this.dialogTrip.cancelledAt ||
        this.dialogTrip.noShowTime ||
        !this.dialogTrip.allowReview)
    ) {
      return false;
    }
    return this.tripIsCompleted;
  }

  get maxStars(): number {
    if (this.theme.id === Themes.themeKulkukeskus.id) {
      return 4;
    }
    return 5;
  }

  get isReviewDisabled() {
    return this.reviewGiven;
  }

  get dialogTrip(): ITrip {
    const id = this.trackingLink
      ? this.trackingLink.id
      : Number.parseInt(this.$route.params.id, 10);
    const date = this.trackingLink
      ? this.trackingLink.date
      : this.$route.params.date;
    const trip = this.$store.getters["trip/tripById"](id, date);
    if (trip) {
      if (trip.reviews && trip.reviews.length > 0) {
        this.reviewComment = trip.reviews[0].comment;
        this.driverStars = trip.reviews[0].driverStars;
        this.experienceStars = trip.reviews[0].experienceStars;
        this.reviewGiven = true;
      }
    }
    return trip;
  }

  get carLocation(): ICarLocation | null {
    if (!this.dialogTrip || !this.dialogTrip.status) {
      return null;
    }
    return this.dialogTrip.status.carLocation || null;
  }

  @Watch("dialogTrip", { immediate: true }) async onDialogTripChanged(
    newVal: any,
    oldVal: any
  ) {
    const isNew = oldVal === null || oldVal === undefined;
    if (
      this.dialogTrip &&
      this.dialogTrip.allowEtaQuery &&
      isNew &&
      !this.statusInterval
    ) {
      await this.updateTripStatus();
    }
  }

  closeTripDialog() {
    this.cancelling = false;
    this.cancelReason = "";
    this.$router.go(-1);
  }

  canceltrip() {
    this.cancelling = true;
    this.$store
      .dispatch("trip/cancelTrips", {
        reason: this.cancelReason,
        trips: [this.dialogTrip],
      })
      .then(() => {
        this.cancelTripDialogOpen = false;
        this.closeTripDialog();
      });
  }

  reviewtrip() {
    this.reviewing = true;
    this.$store
      .dispatch("trip/reviewTrip", {
        trip: this.dialogTrip,
        comment: this.reviewComment,
        driverStars: this.driverStars,
        experienceStars: this.experienceStars,
      })
      .then(() => {
        this.closeTripDialog();
      });
  }

  async updateTrackingTrip() {
    if (!this.trackingInterval) {
      this.setIntervalForTrackingUpdates();
    }

    const trip = await restService.trips.tracked(this.$route.params.link);

    if (!trip) {
      this.clearStatusIntervalsAndTimeouts();
      return;
    }

    const names = trip.passenger.name.split(" ");
    const letters = names.filter((x) => x).map((n) => n[0].toUpperCase());
    letters.length = letters.length > 2 ? 2 : letters.length;
    trip.passenger.avatarText = `${letters.join("")}`;
    // TODO: What's this, how can this be done with this.$randomColor(trip.passenger.id)?
    // trip.passenger.avatarColor = 'primary';
    this.trackingLink = {
      link: this.$route.params.link,
      id: trip.id,
      date: trip.startDateTime.split("T")[0],
    };
    this.$store.commit("trip/addOrReplaceTrip", trip);

    if (trip.status && this.isFinalTripStatus(trip.status.code)) {
      this.clearStatusIntervalsAndTimeouts();
    }
  }

  setIntervalForTrackingUpdates() {
    const diffToFastQueryStart = this.calculateDiffToFastQueryStart();
    const startingInterval =
      diffToFastQueryStart < 0
        ? TripsEdit.QUERY_FAST_INTERVAL
        : TripsEdit.QUERY_DEFAULT_INTERVAL;
    this.trackingInterval = setInterval(() => {
      this.updateTrackingTrip();
    }, startingInterval);

    // If time to set fast interval is in the future, set timeout to engage fast interval
    if (diffToFastQueryStart > 0) {
      this.trackingStartFastQueryTimeout = setTimeout(() => {
        clearInterval(this.trackingInterval);
        this.trackingInterval = setInterval(() => {
          this.updateTrackingTrip();
        }, TripsEdit.QUERY_FAST_INTERVAL);
      }, diffToFastQueryStart);
    }

    const diffToFastQueryEnd = this.calculateDiffToFastQueryEnd();

    // If time to end fast query interval is in the future, set timeout to set interval back to default
    if (diffToFastQueryEnd > 0) {
      this.trackingEndFastQueryTimeout = setTimeout(() => {
        clearInterval(this.trackingInterval);
        this.trackingInterval = setInterval(() => {
          this.updateTrackingTrip();
        }, TripsEdit.QUERY_DEFAULT_INTERVAL);
      }, diffToFastQueryEnd);
    }
  }

  async updateTripStatus() {
    if (!this.statusInterval) {
      this.setIntervalForStatusUpdates();
    }
    if (!this.dialogTrip.startDateTime) {
      return;
    }

    const now = moment();
    const startTime = moment(this.dialogTrip.startDateTime);
    const diffToStart = startTime.diff(now, "m");

    if (diffToStart > TripsEdit.QUERY_CUTOFF_MINUTES) {
      return;
    }

    const date = startTime.format("YYYY-MM-DD");

    const request: ITripStatusRequest = {
      passengerId: this.dialogTrip.passenger.id,
      tripId: this.dialogTrip.id,
      date,
    };

    const trip = await restService.trips.withStatus(request);
    if (!trip) {
      this.clearStatusIntervalsAndTimeouts();
      return;
    }

    this.$store.commit("trip/addOrReplaceTrip", trip);
    if (trip.status && this.isFinalTripStatus(trip.status.code)) {
      this.clearStatusIntervalsAndTimeouts();
    }
  }

  setIntervalForStatusUpdates() {
    const today = moment().date();

    if (moment(this.dialogTrip.startDateTime).date() < today) {
      return;
    }

    const diffToFastQueryStart = this.calculateDiffToFastQueryStart();

    const startingInterval =
      diffToFastQueryStart < 0
        ? TripsEdit.QUERY_FAST_INTERVAL
        : TripsEdit.QUERY_DEFAULT_INTERVAL;
    this.statusInterval = setInterval(() => {
      this.updateTripStatus();
    }, startingInterval);

    // If time to set fast interval is in the future, set timeout to engage fast interval
    if (diffToFastQueryStart > 0) {
      this.statusStartFastQueryTimeout = setTimeout(() => {
        clearInterval(this.statusInterval);
        this.statusInterval = setInterval(() => {
          this.updateTripStatus();
        }, TripsEdit.QUERY_FAST_INTERVAL);
      }, diffToFastQueryStart);
    }

    const diffToFastQueryEnd = this.calculateDiffToFastQueryEnd();

    // If time to end fast query interval is in the future, set timeout to set interval back to default
    if (diffToFastQueryEnd > 0) {
      this.statusEndFastQueryTimeout = setTimeout(() => {
        clearInterval(this.statusInterval);
        this.statusInterval = setInterval(() => {
          this.updateTripStatus();
        }, TripsEdit.QUERY_DEFAULT_INTERVAL);
      }, diffToFastQueryEnd);
    }
  }

  calculateDiffToFastQueryStart() {
    const now = moment();
    const startTime = moment(this.dialogTrip.startDateTime);
    const fastQueryStart = moment(startTime).subtract(
      TripsEdit.QUERY_INCREASE_MINUTES,
      "m"
    );
    return fastQueryStart.diff(now);
  }

  calculateDiffToFastQueryEnd() {
    const now = moment();
    const endTime = moment(this.dialogTrip.endDateTime);
    const fastQueryEnd = moment(endTime).add(
      TripsEdit.QUERY_INCREASE_MINUTES,
      "m"
    );
    return fastQueryEnd.diff(now);
  }

  clearStatusIntervalsAndTimeouts() {
    if (this.trackingInterval) {
      clearInterval(this.trackingInterval);
      this.trackingInterval = null;
    }
    if (this.trackingStartFastQueryTimeout) {
      clearTimeout(this.trackingStartFastQueryTimeout);
    }
    if (this.trackingEndFastQueryTimeout) {
      clearTimeout(this.trackingEndFastQueryTimeout);
    }

    if (this.statusInterval) {
      clearInterval(this.statusInterval);
      this.statusInterval = null;
    }
    if (this.statusStartFastQueryTimeout) {
      clearTimeout(this.statusStartFastQueryTimeout);
    }
    if (this.statusEndFastQueryTimeout) {
      clearTimeout(this.statusEndFastQueryTimeout);
    }
  }

  isFinalTripStatus(status: TripStatusCode) {
    const finalTripStatuses = [
      TripStatusCode.Cancelled,
      TripStatusCode.NoShow,
      TripStatusCode.DropOff,
    ];
    return finalTripStatuses.includes(status);
  }

  updateTripCompletion() {
    if (this.dialogTrip && this.dialogTrip.allowReview) {
      const now = new Date();
      const nowAsMinutes = this.millisToMinutes(now.getTime());
      const tripScheduledDropOff = new Date(this.dialogTrip.endDateTime);
      const dropOffAsMinutes = this.millisToMinutes(
        tripScheduledDropOff.getTime()
      );
      if (this.dialogTrip.noShowTime || this.dialogTrip.cancelledAt) {
        clearInterval(this.tripCompletionInterval);
      } else if (
        this.dialogTrip.pickupTime &&
        nowAsMinutes - dropOffAsMinutes >= 10
      ) {
        this.tripIsCompleted = true;
        clearInterval(this.tripCompletionInterval);
      } else if (this.dialogTrip.dropoffTime) {
        this.tripIsCompleted = true;
        clearInterval(this.tripCompletionInterval);
      }
    }
  }

  millisToMinutes(millis: number) {
    const minutes = Math.floor(millis / 60000);
    return minutes;
  }

  mounted() {
    if (this.$route.name === "track") {
      this.updateTrackingTrip();
    }

    if (this.dialogTrip) {
      // #5929 forbids programmatic focus on page change
      // (this.$refs.pageTitleRef as HTMLElement).focus();
    }

    if (
      this.dialogTrip &&
      (this.dialogTrip.allowReview || !this.dialogTrip.cancelledAt)
    ) {
      this.updateTripCompletion();
      this.tripCompletionInterval = setInterval(() => {
        this.updateTripCompletion();
      }, 60 * 1000);
    }
  }

  beforeDestroy() {
    if (this.tripCompletionInterval) {
      clearInterval(this.tripCompletionInterval);
    }
    this.clearStatusIntervalsAndTimeouts();
  }
}
